import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Logo from '../assets/logo.png'

class Verified extends React.Component {

  constructor(props) {
    super(props)
    this._setState = this._setState.bind(this)
  }

  _setState(changes) {
    let state = this.state
    state = Object.assign({}, state, changes)
    this.setState(state)
  }

  render() {
    return (
      <Grid
        container
        alignItems='center'
        justify='center'
        style={{ minHeight: '100vh', padding: 30 }}
      >
        <Grid item xs={10} sm={6} md={5} lg={3} xl={2}>
          <div style={{ textAlign: 'center' }}>
            <img src={Logo} alt='Noonlight Logo' className='logo' />
            <svg className="checkSvg" version='1.1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 130.2 130.2'>
              <circle className='path circle' fill='none' stroke='#73AF55' strokeWidth='6' strokeMiterlimit='10' cx='65.1' cy='65.1' r='62.1'/>
              <polyline className='path check' fill='none' stroke='#73AF55' strokeWidth='6' strokeLinecap='round' strokeMiterlimit='10' points='100.2,40.2 51.5,88.8 29.8,67.5'/>
            </svg>
            <Typography variant="h6" gutterBottom>
              Verification Successful!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Your new phone number has been successfully verified
              and changed on your profile. If you have the Noonlight
              App installed, you will need to reinstall it for this
              change to take effect.
            </Typography>
            <Typography variant="body2" gutterBottom>
              You can now safely close this window.
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default Verified