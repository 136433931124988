import React from 'react'
import { BrowserRouter as Router, Route } from "react-router-dom"
import PhoneEntry from './components/PhoneEntry'
import CodeVerify from './components/CodeVerify'
import Verified from './components/Verified'
import Expired from './components/Expired'

class App extends React.Component {
  render() {
    return (
      <Router>
        <div>
          <Route path="/profile/:profileID/phone" exact component={PhoneEntry} />
          <Route path="/profile/:profileID/verify/:phone/:verificationID" exact component={CodeVerify} />
          <Route path="/verified" exact component={Verified} />
          <Route path="/expired" exact component={Expired} />
        </div>
      </Router>
    )
  }
}

export default App
