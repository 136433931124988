import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Logo from '../assets/logo.png'
import config from '../config'

class CodeVerify extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      code: '',
      loading: false,
      error: false,
      errorText: ''
    }
    
    this._setState = this._setState.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  _setState(changes) {
    let state = this.state
    state = Object.assign({}, state, changes)
    this.setState(state)
  }
  
  _handleSubmit(e) {
    e.preventDefault()
    this._setState({ loading: true })
    fetch(`${config.nlAccountServices}/v1/profiles/${this.props.match.params.profileID}/phone/verify/${this.props.match.params.verificationID}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        code: this.state.code,
        phone: `+${this.props.match.params.phone}`
      })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 400) {
        return response.json()
      } else {
        throw new Error('Error Response')
      }
    })
    .then(data => {
      this._setState({ loading: false })
      if(data.valid) {
        const params = new URLSearchParams(this.props.location.search)
        const useDeeplink = params.get('iOSDeeplink')
        if(useDeeplink) {
          window.location = `noonlight://update/phone/${this.props.match.params.phone.slice(-10)}`
        } else {
          this.props.history.push(`/verified`)
        }
      } else {
        this._setState({
          loading: false,
          error: true,
          errorText: 'Invalid code. Please try again.'
        })
      }
    })
    .catch(err => {
      this._setState({
        loading: false,
        error: true,
        errorText: 'Something went wrong. Please try again.'
      })
      console.log(err)
    })
  }

  _handleChange(e) {
    this._setState({ code: e.target.value })
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', padding: 30 }}
      >
        <Grid item xs={10} sm={6} md={5} lg={3} xl={2}>
        { !this.state.loading &&
          <div>
            <img src={Logo} alt='Noonlight Logo' className='logo' />
            <Typography variant="h6" style={{ marginBottom: 150 }}>
              Change Phone Number
            </Typography>
              <form autoComplete='off' onSubmit={this._handleSubmit}>
                <FormControl fullWidth={true} >
                <TextField
                      id="code"
                      label="Code"
                      margin="normal"
                      required={true}
                      helperText={
                        this.state.error ? this.state.errorText : 'Check for SMS with code'
                      }
                      error={this.state.error}
                      style={{marginBottom: 30}}
                      onChange={this._handleChange}
                      />
                  <br/>
                  <Button variant="contained" color="primary" label="Submit" type="submit" >Verify</Button>
                  </FormControl>
              </form>
            </div>
          }
          { this.state.loading &&
          <div style={{ textAlign: 'center' }}>
            <CircularProgress />
          </div>
          }
        </Grid>
      </Grid>
    )
  }
}

export default CodeVerify