import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import InputMask from 'react-input-mask'
import Logo from '../assets/logo.png'
import config from '../config'

class PhoneEntry extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      phone: '',
      loading: true,
      error: false,
      errorText: '',
      loadingText: 'Verifying link. Please wait...'
    }

    this._setState = this._setState.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._handleSubmit = this._handleSubmit.bind(this)
  }

  componentWillMount() {
    fetch(`${config.nlAccountServices}/v1/profiles/${this.props.match.params.profileID}/phone/verify`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: '{}'
    })
    .then(response => {
      if (response.status === 401) {
        this.props.history.push(`/expired`)
      } else {
        this._setState({
          loadingText: '',
          loading: false
        })
      }
    })
  }

  _setState(changes) {
    let state = this.state
    state = Object.assign({}, state, changes)
    this.setState(state)
  }

  _handleSubmit(e) {
    e.preventDefault()
    this._setState({ loading: true })
    const phone = `${this.state.phone.replace(/[^0-9]/g, '')}`
    fetch(`${config.nlAccountServices}/v1/profiles/${this.props.match.params.profileID}/phone/verify`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        phone: `+${phone}`
      })
    })
    .then(response => {
      if (response.status >= 200 && response.status < 400) {
        return response.json()
      } else {
        throw new Error('Error Response')
      }
    })
    .then(data => {
      this._setState({ loading: false })
      const { requestId } = data

      const params = new URLSearchParams(this.props.location.search)
      const useDeeplink = params.get('iOSDeeplink')
      if(useDeeplink) {
        this.props.history.push(`/profile/${this.props.match.params.profileID}/verify/${phone}/${requestId}?iOSDeeplink=${useDeeplink}`)
      } else {
        this.props.history.push(`/profile/${this.props.match.params.profileID}/verify/${phone}/${requestId}`)
      }
    })
    .catch(err => {
      this._setState({
        loading: false,
        error: true,
        errorText: 'Something went wrong. Please try again.'
      })
      console.log(err)
    })
  }

  _handleChange(e) {
    this._setState({ phone: e.target.value })
  }

  render() {
    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        style={{ minHeight: '100vh', padding: 30 }}
      >
        <Grid item xs={10} sm={6} md={5} lg={3} xl={2}>
          { !this.state.loading && 
            <div>
              <img src={Logo} alt='Noonlight Logo' className='logo' />
              <Typography variant="h6" style={{ marginBottom: 150 }}>
                Change Phone Number
              </Typography>
              <form autoComplete='off' onSubmit={this._handleSubmit}>
                <FormControl fullWidth={true} >
                  <InputMask mask="+1 (999) 999-9999" maskChar=" " onChange={this._handleChange} value={this.state.phone}>
                  {
                    (inputProps) => <TextField
                      id="phone"
                      type="tel"
                      label="Phone"
                      margin="normal"
                      helperText={
                        this.state.error ? this.state.errorText : '10-digit US phone number.'
                      }
                      required={true}
                      style={{marginBottom: 30}}
                      error={this.state.error}
                      />
                  }
                  </InputMask>
                  <br/>
                  <Button variant='contained' color='primary' label='Submit' type='submit' >Get Code</Button>
                  </FormControl>
              </form>
            </div>
          }
          { this.state.loading &&
          <div style={{ textAlign: 'center' }}>
            { this.state.loadingText !== '' &&
            <Typography variant="body1" style={{ marginBottom: 30 }}>
              {this.state.loadingText}
            </Typography>
            }
            <CircularProgress />
          </div>
          }
        </Grid>
      </Grid>
    )
  }
}

export default PhoneEntry