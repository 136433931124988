import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Logo from '../assets/logo.png'

class Expired extends React.Component {

  constructor(props) {
    super(props)
    this._setState = this._setState.bind(this)
  }

  _setState(changes) {
    let state = this.state
    state = Object.assign({}, state, changes)
    this.setState(state)
  }

  render() {
    return (
      <Grid
        container
        alignItems='center'
        justify='center'
        style={{ minHeight: '100vh', padding: 30 }}
      >
        <Grid item xs={10} sm={6} md={5} lg={3} xl={2}>
          <div style={{ textAlign: 'center' }}>
            <img src={Logo} alt='Noonlight Logo' className='logo' />
            <Typography variant="h6" gutterBottom>
              This link has expired!
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please contact Noonlight support if you still
              need to change your phone number.
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  }
}

export default Expired